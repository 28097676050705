import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

function initReactApp() {
  let chatRoot = document.getElementById("chat-root");

  // If chat-root doesn't exist, create it
  if (!chatRoot) {
    chatRoot = document.createElement("div");
    chatRoot.id = "chat-root";
    document.body.appendChild(chatRoot);
  }

  const root = createRoot(chatRoot);
  root.render(
      <App />
  );
}

// For local development
if (process.env.NODE_ENV === "development") {
  initReactApp();
}

// For production (used by chat-loader.js)
//@ts-ignore
window.initReactApp = initReactApp;

/*
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

..........
import React from 'react';
import App from './App';  
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('chat-root'));
root.render(<App />);
.........
const renderChatWidget = (elementId) => {
  const targetElement = document.getElementById(elementId);

  if (targetElement) {
    const root = ReactDOM.createRoot(targetElement);
    root.render(<App />);
  } else {
    console.error(`Element with ID ${elementId} not found!`);
  }
};

// Expose the renderChatWidget function to the global window object
window.renderChatWidget = renderChatWidget;
*/
