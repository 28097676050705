export const toolDisplayMapping = {
  'car_stock_search': 'Sto controllando quali auto abbiamo disponibili per te...',
  'validate': 'Un attimo mentre verifico i tuoi dati per offrirti il miglior servizio possibile...',
  'knowledge_search_engine': 'Sto cercando le informazioni',
  'booking_': 'Sto controllando la nostra agenda',
  'book_': 'Sto controllando la nostra agenda',
  "consultant_intervention": "Sto avvisando uno dei nostri consulenti per aiutarti",
  'customer_registration': 'Ancora qualche secondo...',
 
};

export const getToolDisplay = (tool) => {
  // First try exact match
  if (toolDisplayMapping[tool]) {
    return toolDisplayMapping[tool];
  }

  // If no exact match, try prefix match
  const matchingKey = Object.keys(toolDisplayMapping).find(key => 
    tool.startsWith(key)
  );

  return matchingKey ? toolDisplayMapping[matchingKey] : tool;
};