import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  LinearProgress,
  useTheme,
} from "@mui/material";

import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Help as HelpIcon,
  Lock as LockIcon,
  Campaign as CampaignIcon,
  Shield as ShieldIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Save as SaveIcon,
  EventAvailable as EventAvailableIcon,
} from "@mui/icons-material";

import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchRegistrationForm, submitFormResponse } from "../../api/api";
import { trackChatEvent } from "../../utils/analytics";
import { countries } from './countries';
import styled from '@emotion/styled';

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '8px 14px',
  },
  '& .country-flag': {
    fontSize: '20px',
    lineHeight: 1,
  },
  '& .country-prefix': {
    color: theme.palette.text.secondary,
    fontSize: '14px',
  }
}));

const PersonalDataForm = ({ onSubmit, onSendMessage, setIsLoading, activeTrigger }) => {
  const [formData, setFormData] = useState(null);
  const [formState, setFormState] = useState({});
  const [originalFormState, setOriginalFormState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formDisplayCount, setFormDisplayCount] = useState(1);
  const [hasChanges, setHasChanges] = useState(false);
  const theme = useTheme();
  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const [formAnimation, setFormAnimation] = useState(false);
  const [fieldTransition, setFieldTransition] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({ code: 'IT', prefix: '+39', flag: '🇮🇹' });

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const response = await fetchRegistrationForm();
      setFormData(response.data);
      initializeFormState(response.data);
    } catch (err) {
      setError("Impossibile recuperare i dati del modulo. Riprova più tardi.");
    }

    const savedFormState = localStorage.getItem("formState");
    const savedIsSubmitted = localStorage.getItem("isSubmitted");

    if (savedFormState) {
      const parsedState = JSON.parse(savedFormState);
      setFormState(parsedState);
      setOriginalFormState(parsedState);
    }
    if (savedIsSubmitted) {
      setIsSubmitted(JSON.parse(savedIsSubmitted));
      setIsEditing(false);
    }

    // Tracking form display count
    const storedCount = parseInt(localStorage.getItem("form_display_count") || "1", 10);
    setFormDisplayCount(storedCount);
    trackChatEvent.formInteraction("personal_data", "form_shown", {
      display_count: storedCount,
    });
    localStorage.setItem("form_display_count", (storedCount + 1).toString());
  };

  const initializeFormState = (data) => {
    const savedFormState = localStorage.getItem("formState");
    if (savedFormState) {
      const parsedState = JSON.parse(savedFormState);
      setFormState(parsedState);
      setIsSubmitted(!!parsedState.id);
      setIsEditing(!parsedState.id);
    } else {
      const initialState = {};
      data.pages.forEach((page) => {
        page.groups.forEach((group) => {
          group.fields
            .filter(field => !field.is_hidden)
            .forEach((field) => {
              if (field.field_type === "checkbox") {
                initialState[field.field_id] = [];
              } else {
                initialState[field.field_id] = "";
              }
            });
        });
      });
      setFormState(initialState);
      localStorage.setItem("formState", JSON.stringify(initialState));
    }
  };

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const isValidPhone = (phone) => {
    // Accepts numbers with optional '+' prefix, minimum 8 digits
    const re = /^\+?[0-9]{8,}$/;
    return re.test(String(phone).replace(/\s+/g, ''));
  };

  const handleChange = (fieldId, value, fieldType) => {
    setFormState((prevState) => {
      let newState;
      let isValid = true;
      let errorMessage = '';

      // Validate based on field type
      if (fieldType === 'email' && value) {
        isValid = isValidEmail(value);
        errorMessage = isValid ? '' : 'Email non valida';
      } else if (fieldType === 'number' && value) {
        const field = formData?.pages.flatMap(page => 
          page.groups.flatMap(group => 
            group.fields.find(f => f.field_id === fieldId)
          )
        ).filter(Boolean)[0];

        if (field?.keyword?.toLowerCase().includes('phone')) {
          isValid = isValidPhone(value);
          errorMessage = isValid ? '' : 'Numero di telefono non valido';
        }
      }

      if (fieldType === "checkbox") {
        const currentValues = prevState[fieldId] || [];
        const newValues = currentValues.includes(value)
          ? currentValues.filter((v) => v !== value)
          : [...currentValues, value];
        newState = { ...prevState, [fieldId]: newValues };
      } else {
        newState = { 
          ...prevState, 
          [fieldId]: value,
          [`${fieldId}_error`]: errorMessage
        };
      }
      
      setHasChanges(JSON.stringify(newState) !== JSON.stringify(originalFormState));
      localStorage.setItem("formState", JSON.stringify(newState));
      return newState;
    });
  };

  const evaluateCondition = useCallback(
    (conditionsArray) => {
      const evaluateSingleCondition = (condition) => {
        const { fieldId, type, value } = condition;
        const fieldValue = formState[fieldId];

        if (Array.isArray(fieldValue)) {
          // For checkbox fields
          switch (type) {
            case "equals":
              return (
                JSON.stringify(fieldValue.sort()) ===
                JSON.stringify(
                  value
                    .split(",")
                    .map((v) => v.trim())
                    .sort()
                )
              );
            case "includes":
              return fieldValue.includes(value);
            case "not_includes":
              return !fieldValue.includes(value);
            default:
              return false;
          }
        } else {
          // For other fields
          switch (type) {
            case "equals":
              return fieldValue === value;
            case "not_equals":
              return fieldValue !== value;
            case "contains":
              return String(fieldValue).includes(value);
            case "not_contains":
              return !String(fieldValue).includes(value);
            case "greater_than":
              return Number(fieldValue) > Number(value);
            case "less_than":
              return Number(fieldValue) < Number(value);
            default:
              return true;
          }
        }
      };

      let result = true;
      let currentOperator = "AND";

      for (let i = 0; i < conditionsArray.length; i++) {
        const condition = conditionsArray[i];
        const conditionResult = evaluateSingleCondition(condition);

        if (currentOperator === "AND") {
          result = result && conditionResult;
        } else {
          result = result || conditionResult;
        }

        currentOperator = condition.logicalOperator || "AND";

        if (currentOperator === "AND" && !result) break;
        if (currentOperator === "OR" && result) break;
      }

      return result;
    },
    [formState]
  );

  const evaluateFieldConditions = useCallback(
    (conditions) => {
      // Return true for empty, null, or undefined conditions
      if (!conditions || conditions === "[]" || conditions === null || conditions === "") return true;
      
      try {
        // Handle string "null" case
        if (conditions === "null") return true;
        
        // Handle case where conditions is already an array
        if (Array.isArray(conditions)) {
          return conditions.length === 0 ? true : evaluateCondition(conditions);
        }
        
        const parsedConditions = JSON.parse(conditions);
        // Handle empty array case
        if (Array.isArray(parsedConditions) && parsedConditions.length === 0) return true;
        
        return evaluateCondition(parsedConditions);
      } catch (error) {
        console.warn("Error parsing field conditions:", error, "Conditions:", conditions);
        // Return true on parse error to show the field by default
        return true;
      }
    },
    [evaluateCondition]
  );

  const visibleFields = useMemo(() => {
    if (!formData || !formData.pages) return [];
    return formData.pages.flatMap((page) =>
      page.groups.flatMap((group) =>
        group.fields.filter((field) => 
          field && 
          !field.is_hidden &&
          evaluateFieldConditions(field?.conditions)
        )
      )
    );
  }, [formData, evaluateFieldConditions]);

  const validateForm = useCallback(() => {
    if (!formData) return false;

    const requiredFields = formData.pages.flatMap((page) =>
      page.groups.flatMap((group) =>
        group.fields
          .filter(field => !field.is_hidden)
          .filter(
            (field) => field.required && evaluateFieldConditions(field.conditions)
          )
      )
    );

    const isValid = requiredFields.every((field) => {
      const value = formState[field.field_id];
      return value !== undefined && value !== "" && value.length !== 0;
    });

    setIsFormValid(isValid);
    return isValid;
  }, [formData, formState, evaluateFieldConditions]);

  useEffect(() => {
    validateForm();
  }, [formState, validateForm]);

  const calculateProgress = () => {
    if (!formData) return 0;
    const allFields = formData.pages.flatMap((page) =>
      page.groups.flatMap((group) =>
        group.fields
          .filter(field => !field.is_hidden)
          .filter((field) => evaluateFieldConditions(field.conditions))
      )
    );
    const filledCount = allFields.filter((field) => {
      const val = formState[field.field_id];
      return val && val.length !== 0;
    }).length;
    return (filledCount / allFields.length) * 100;
  };

  const progress = calculateProgress();

  const renderDescription = (description, linkType) => {
    if (!description) return null;

    const parts = description.split(/(https?:\/\/\S+)/gi);
    return parts.map((part, index) => {
      if (part.match(/(https?:\/\/\S+)/gi)) {
        const linkStyle = {
          fontWeight: "bold",
          textDecoration: "underline",
          display: "inline-flex",
          alignItems: "center",
          gap: "4px",
          ...(linkType === "gdpr"
            ? { color: theme.palette.info.main }
            : { color: theme.palette.secondary.main }),
        };

        const icon =
          linkType === "gdpr" ? (
            <LockIcon fontSize="small" />
          ) : (
            <CampaignIcon fontSize="small" />
          );

        return (
          <Link
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            sx={linkStyle}
          >
            {icon}
            {linkType === "gdpr" ? "Link Privacy Policy" : "Link "}
          </Link>
        );
      }
      return part;
    });
  };

  const renderField = (field) => {
    const value = formState[field.field_id] || "";
    const disabled = !isEditing;

    const commonProps = {
      fullWidth: true,
      required: field.required,
      label: field.label,
      disabled: disabled,
      value: value,
      onChange: (e) => handleChange(field.field_id, e.target.value, field.field_type),
      sx: { 
        mb: 1.5,
        '& .MuiInputBase-root': {
          width: '100%',
          height: '56px',
        },
        '& .MuiInputBase-input': {
          height: '24px',
          padding: '16px 14px',
          fontSize: '16px',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: '8px',
          },
        },
        '& .MuiInputLabel-root': {
          fontSize: '16px',
        },
      },
    };

    const showDescriptionTooltip = field.description && field.keyword !== "gdpr" && field.keyword !== "marketing";

    const tooltipIcon = showDescriptionTooltip ? (
      <Tooltip title={field.description} arrow placement="top-start">
        <IconButton size="small">
          <HelpIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    ) : null;

    // Render by field type
    let fieldComponent;
    switch (field.field_type) {
      case "text":
      case "textarea":
        fieldComponent = (
          <TextField
            {...commonProps}
            multiline={field.field_type === "textarea"}
            rows={field.field_type === "textarea" ? 4 : 1}
          />
        );
        break;
      case "email":
        fieldComponent = (
          <TextField
            {...commonProps}
            type="email"
            error={!!formState[`${field.field_id}_error`]}
            helperText={formState[`${field.field_id}_error`]}
            onChange={(e) => handleChange(field.field_id, e.target.value, field.field_type)}
          />
        );
        break;
      case "number":
        const isPhone = field.keyword && field.keyword.toLowerCase().includes("phone");
        fieldComponent = isPhone ? (
          <FormControl fullWidth error={!!formState[`${field.field_id}_error`]} sx={{ mb: 1.5 }}>
            <InputLabel>{field.label}</InputLabel>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <StyledSelect
                value={selectedCountry.code}
                onChange={(e) => {
                  const country = countries.find(c => c.code === e.target.value);
                  setSelectedCountry(country);
                }}
                sx={{
                  width: '110px',
                  minWidth: '110px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  }
                }}
              >
                {countries.map((country) => (
                  <MenuItem 
                    key={country.code} 
                    value={country.code}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      py: 1,
                    }}
                  >
                    <span className="country-flag">{country.flag}</span>
                    <span className="country-prefix">{country.prefix}</span>
                  </MenuItem>
                ))}
              </StyledSelect>
              <TextField
                {...commonProps}
                type="tel"
                error={!!formState[`${field.field_id}_error`]}
                helperText={formState[`${field.field_id}_error`]}
                inputProps={{ 
                  pattern: "[0-9]*", 
                  inputMode: "numeric",
                  placeholder: "Inserisci il numero"
                }}
                value={value.replace(selectedCountry.prefix, '')}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9]/g, '');
                  handleChange(field.field_id, `${selectedCountry.prefix}${newValue}`, field.field_type);
                }}
                sx={{
                  ...commonProps.sx,
                  flex: 1,
                }}
              />
            </Box>
          </FormControl>
        ) : (
          <TextField
            {...commonProps}
            type="number"
          />
        );
        break;
      case "select":
        {
          const options = field.options ? field.options.split(",") : [];
          fieldComponent = (
            <FormControl fullWidth required={field.required} disabled={disabled} sx={{ mb: 2 }}>
              <InputLabel>{field.label}</InputLabel>
              <Select
                label={field.label}
                value={value}
                onChange={(e) => handleChange(field.field_id, e.target.value, field.field_type)}
              >
                {options.map((opt) => {
                  const trimmed = opt.trim();
                  return (
                    <MenuItem key={trimmed} value={trimmed}>
                      {trimmed}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        }
        break;
      case "radio":
        {
          const options = field.options ? field.options.split(",") : [];
          fieldComponent = (
            <FormControl component="fieldset" required={field.required} disabled={disabled} sx={{ mb: 2 }}>
              <Typography sx={{ mb: 1 }}>{field.label}</Typography>
              <RadioGroup
                value={value}
                onChange={(e) => handleChange(field.field_id, e.target.value, field.field_type)}
              >
                {options.map((opt) => {
                  const trimmed = opt.trim();
                  return (
                    <FormControlLabel
                      key={trimmed}
                      value={trimmed}
                      control={<Radio />}
                      label={trimmed}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          );
        }
        break;
      case "checkbox":
        {
          const checkedValues = value;
          if (field.keyword === "gdpr" || field.keyword === "marketing") {
            fieldComponent = (
              <Box mt={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedValues.includes(field.keyword)}
                        onChange={() => handleChange(field.field_id, field.keyword, "checkbox")}
                        disabled={disabled}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" gap={1}>
                        {checkedValues.includes(field.keyword) ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="disabled" />
                        )}
                        <Typography>{field.label.toUpperCase()}</Typography>
                      </Box>
                    }
                  />
                  <Typography variant="body2" mt={1}>
                    {renderDescription(field.description, field.keyword)}
                  </Typography>
                </FormGroup>
              </Box>
            );
          } else {
            const options = field.options ? field.options.split(",") : [];
            fieldComponent = (
              <FormGroup sx={{ mb: 2 }}>
                <Typography sx={{ mb: 1 }}>{field.label}</Typography>
                {options.map((opt) => {
                  const trimmed = opt.trim();
                  return (
                    <FormControlLabel
                      key={trimmed}
                      control={
                        <Checkbox
                          checked={checkedValues.includes(trimmed)}
                          onChange={() => handleChange(field.field_id, trimmed, "checkbox")}
                          disabled={disabled}
                        />
                      }
                      label={trimmed}
                    />
                  );
                })}
              </FormGroup>
            );
          }
        }
        break;
      default:
        fieldComponent = null;
    }

    return (
      <Box key={field.field_id} display="flex" alignItems="flex-start" gap={1}>
        <Box flexGrow={1}>{fieldComponent}</Box>
        {tooltipIcon}
      </Box>
    );
  };

  const handleCreateSubmit = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    setError(null);
    try {
      const emailField = formData.pages.flatMap(page => 
        page.groups.flatMap(group => 
          group.fields.find(f => f.field_type === 'email')
        )
      ).filter(Boolean)[0];

      const phoneField = formData.pages.flatMap(page => 
        page.groups.flatMap(group => 
          group.fields.find(f => f.keyword?.toLowerCase().includes('phone'))
        )
      ).filter(Boolean)[0];

      const emailValue = emailField ? formState[emailField.field_id] : '';
      const phoneValue = phoneField ? formState[phoneField.field_id] : '';
      const isEmailAvailable = emailField && !emailField.is_hidden && emailValue;

      const fieldResponses = formData.pages.flatMap((page) =>
        page.groups.flatMap((group) =>
          group.fields.map((field) => {
            // If email is not available and this is an email field, use phone value
            if (field.field_type === 'email' && !isEmailAvailable && phoneValue) {
              return {
                field: field.id,
                value: `${phoneValue}`,
              };
            }
            // If email is not available and this is a phone field, format the value
            if (field.keyword?.toLowerCase().includes('phone') && !isEmailAvailable) {
              return {
                field: field.id,
                value: `${formState[field.field_id] || ''}`,
              };
            }
            // Default case
            return {
              field: field.id,
              value: formState[field.field_id] || '',
            };
          })
        )
      );

      const result = await submitFormResponse(formData.id, fieldResponses, activeTrigger);
      trackChatEvent.formInteraction("personal_data", "form_submitted", {
        display_count: formDisplayCount,
      });

      saveFieldsToLocalStorage();
      setIsSubmitted(true);
      setIsEditing(false);
      setOriginalFormState({ ...formState, id: result.id });
      localStorage.setItem("isSubmitted", JSON.stringify(true));
      localStorage.setItem("formState", JSON.stringify({ ...formState, id: result.id }));
      
      await onSendMessage("Dati personali salvati", null, null, formState);

      setSnackbar({
        open: true,
        message: "Modulo inviato con successo!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("Impossibile inviare il modulo. Riprova.");
      trackChatEvent.formInteraction("personal_data", "form_submission_failed", {
        display_count: formDisplayCount,
        error: error.message,
      });
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  const handleUpdateSubmit = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    setError(null);
    try {
      const emailField = formData.pages.flatMap(page => 
        page.groups.flatMap(group => 
          group.fields.find(f => f.field_type === 'email')
        )
      ).filter(Boolean)[0];

      const phoneField = formData.pages.flatMap(page => 
        page.groups.flatMap(group => 
          group.fields.find(f => f.keyword?.toLowerCase().includes('phone'))
        )
      ).filter(Boolean)[0];

      const emailValue = emailField ? formState[emailField.field_id] : '';
      const phoneValue = phoneField ? formState[phoneField.field_id] : '';
      const isEmailAvailable = emailField && !emailField.is_hidden && emailValue;

      const fieldResponses = formData.pages.flatMap((page) =>
        page.groups.flatMap((group) =>
          group.fields.map((field) => {
            // If email is not available and this is an email field, use phone value
            if (field.field_type === 'email' && !isEmailAvailable && phoneValue) {
              return {
                field: field.id,
                value: `${phoneValue}`,
              };
            }
            // If email is not available and this is a phone field, format the value
            if (field.keyword?.toLowerCase().includes('phone') && !isEmailAvailable) {
              return {
                field: field.id,
                value: `${formState[field.field_id] || ''}`,
              };
            }
            // Default case
            return {
              field: field.id,
              value: formState[field.field_id] || '',
            };
          })
        )
      );

      await submitFormResponse(formData.id, fieldResponses, activeTrigger);
      saveFieldsToLocalStorage();

      setIsEditing(false);
      setOriginalFormState({ ...formState });
      localStorage.setItem("formState", JSON.stringify(formState));
      
      await onSendMessage("Dati personali aggiornati", null, null, formState);

      setSnackbar({
        open: true,
        message: "Dati salvati con successo!",
        severity: "success",
      });
      trackChatEvent.saveForm("personal_data");
    } catch (error) {
      console.error("Form update failed:", error);
      setError("Impossibile aggiornare il modulo. Riprova più tardi.");
      setSnackbar({
        open: true,
        message: "Impossibile aggiornare il modulo. Riprova più tardi.",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  const saveFieldsToLocalStorage = () => {
    const fieldsToSave = [
      { keyword: "is_company" },
      { keyword: "first_name" },
      { keyword: "last_name" },
      { keyword: "name" },
      { keyword: "email" },
      { keyword: "phone" },
      { keyword: "gdpr" },
      { keyword: "marketing" },
    ];

    fieldsToSave.forEach((field) => {
      const fieldId = findFieldIdByKeyword(field.keyword);
      if (fieldId) {
        const value = formState[fieldId];
        if (value !== undefined && value !== null) {
          const storageKey = `aitomotivelab_personalData_${field.keyword}`;
          let storageValue = Array.isArray(value)
            ? value.join(",")
            : typeof value === "object"
            ? JSON.stringify(value)
            : String(value);
          localStorage.setItem(storageKey, storageValue);
        }
      }
    });
  };

  const findFieldIdByKeyword = (keyword) => {
    for (const page of formData?.pages || []) {
      for (const group of page.groups) {
        const field = group.fields.find((f) => f.keyword === keyword);
        if (field) {
          return field.field_id;
        }
      }
    }
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isSubmitted) {
        handleUpdateSubmit();
      } else {
        handleCreateSubmit();
      }
    } else {
      setSnackbar({
        open: true,
        message: "Compila tutti i campi obbligatori.",
        severity: "warning",
      });
    }
  };

  const handleEditToggle = () => {
    if (isEditing) {
      // If currently editing and there are unsaved changes, confirm
      if (hasChanges && !window.confirm("Hai modifiche non salvate. Vuoi scartarle?")) {
        return;
      }
      setFormState(originalFormState);
      setHasChanges(false);
      setIsEditing(false);
    } else {
      // Start editing
      setOriginalFormState({ ...formState });
      setHasChanges(false);
      setIsEditing(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ ...snackbar, open: false });
  };

  const renderCurrentField = () => {
    const currentField = visibleFields[currentFieldIndex];
    if (!currentField) return null;

    return (
      <Box
        sx={{
          width: '100%',
          '& .MuiFormControl-root': {
            marginBottom: 2,
          },
          animation: fieldTransition ? 'slideIn 0.3s ease-out' : 'none',
          '@keyframes slideIn': {
            from: { opacity: 0, transform: 'translateX(20px)' },
            to: { opacity: 1, transform: 'translateX(0)' }
          }
        }}
      >
        {renderField(currentField)}
      </Box>
    );
  };

  const isCurrentFieldValid = () => {
    const currentField = visibleFields[currentFieldIndex];
    if (!currentField) return true;

    const value = formState[currentField.field_id];
    const errorMessage = formState[`${currentField.field_id}_error`];

    // If there's an error message, the field is invalid
    if (errorMessage) return false;

    // If field is required, check if it has a value
    if (currentField.required && (!value || value.length === 0)) return false;

    // For email fields, validate format
    if (currentField.field_type === 'email' && value) {
      return isValidEmail(value);
    }

    // For phone fields, validate format
    if (currentField.field_type === 'number' && 
        currentField.keyword?.toLowerCase().includes('phone') && 
        value) {
      return isValidPhone(value);
    }

    return true;
  };

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!formData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        width: 'auto',
        maxWidth: {
          xs: 'calc(100% - 32px)',
          sm: '600px',
          md: '600px',
          lg: 'calc(100% - 64px)',
        },
        minWidth: {
          xs: '280px',
          sm: '400px',
          md: '400px',
          lg: '260px',
        },
        margin: {
          xs: '8px 16px',
          sm: '8px auto',
          md: '8px auto',
          lg: '8px 0 8px 48px',
        },
        borderRadius: '12px',
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        overflow: 'hidden',
        boxSizing: 'border-box',
        '& > *': {
          maxWidth: '100%',
        },
      }}
    >
      {/* Form header */}
      <Box sx={{ p: 2, pb: 1 }}>
        <Box 
          sx={{ 
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box 
            sx={{ 
              display: 'flex',
              alignItems: 'flex-start',
              gap: 1.5,
              flex: 1,
            }}
          >
            <Box 
              component="span"
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 32,
                height: 32,
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                flexShrink: 0,
              }}
            >
              <EventAvailableIcon sx={{ fontSize: 20 }} />
            </Box>
            
            <Box>
              <Typography 
                sx={{ 
                  fontSize: '16px',
                  fontWeight: 600,
                  color: 'text.primary',
                  mb: 1,
                }}
              >
                Un nostro consulente e' sempre disponibile per darti ulteriori informazioni
              </Typography>
              <Typography 
                sx={{ 
                  fontSize: '14px',
                  color: 'text.secondary',
                  fontWeight: 400,
                }}
              >
                Se vuoi essere ricontattato lasciaci il tuo numero di telefono, altrimenti continua la conversazione in chat
              </Typography>
            </Box>
          </Box>

          {/* Add edit button */}
          {isSubmitted && (
            <IconButton
              onClick={handleEditToggle}
              sx={{ 
                ml: 1,
                color: isEditing ? 'primary.main' : 'action.active',
              }}
            >
              {isEditing ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          )}
        </Box>
      </Box>

      {/* Form fields */}
      <Box sx={{ p: 2, pt: 1 }}>
        {isSubmitted && !isEditing ? (
          // Show read-only view when submitted and not editing
          <Box sx={{ opacity: 0.7 }}>
            {renderCurrentField()}
          </Box>
        ) : (
          // Show editable fields
          <>
            {renderCurrentField()}
            
            {/* Navigation buttons */}
            <Box 
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
              }}
            >
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                disabled={currentFieldIndex === 0}
                onClick={() => {
                  setFieldTransition(true);
                  setTimeout(() => setCurrentFieldIndex(prev => prev - 1), 50);
                  setTimeout(() => setFieldTransition(false), 300);
                }}
                sx={{ 
                  borderRadius: '8px',
                  textTransform: 'none',
                }}
              >
                Precedente
              </Button>
              <Button
                variant="contained"
                endIcon={currentFieldIndex === visibleFields.length - 1 ? 
                  (isSubmitted ? <SaveIcon /> : <CheckCircleIcon />) : 
                  <ArrowForwardIcon />}
                disabled={!isCurrentFieldValid()}
                onClick={() => {
                  setFieldTransition(true);
                  setTimeout(() => {
                    if (currentFieldIndex === visibleFields.length - 1) {
                      handleSubmit({ preventDefault: () => {} });
                    } else {
                      setCurrentFieldIndex(prev => prev + 1);
                    }
                  }, 50);
                  setTimeout(() => setFieldTransition(false), 300);
                }}
                sx={{ 
                  borderRadius: '8px',
                  textTransform: 'none',
                }}
              >
                {currentFieldIndex === visibleFields.length - 1 ? 
                  (isSubmitted ? 'Salva modifiche' : 'Salva') : 
                  'Avanti'}
              </Button>
            </Box>
          </>
        )}
      </Box>

      {/* Add snackbar for feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PersonalDataForm;
