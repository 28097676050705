import { LinearProgress } from "@mui/material";
import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const loadingFade = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

export const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 32px;
  width: 100%;
  max-width: ${(props) => (props.isWide ? "90%" : props.theme.maxWidth)};
  font-family: inherit;
  transition: all 0.3s ease;
  box-shadow: 0 0 00px rgba(0, 0, 0, 0.1);

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    border-radius: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 16px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.sender === "user" ? "flex-end" : "flex-start"};
  width: 100%;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: ${fadeIn} 0.6s ease-out;
`;

export const MessagePaper = styled.div`
  position: relative;
  padding: 16px 24px;
  border-radius: 24px;
  background: ${(props) =>
    props.sender === "user"
      ? props.theme.palette.v2.primary
      : props.theme.palette.v2.secondary};
  color: ${(props) =>
    props.sender === "user"
      ? props.theme.palette.v2.textPrimary
      : props.theme.palette.v2.textSecondary};
  ${(props) => (props.sender === "user" ? `` : `max-width: 75%;`)};
  align-self: ${(props) =>
    props.sender === "user" ? "flex-end" : "flex-start"};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);

  font-family: "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.2px;

  /* Add word wrapping properties */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  /* Ensure proper overflow handling */
  overflow: hidden;

  /* Style text content */
  & p, & h1, & h2, & h3, & h4, & h5, & h6, & span, & div {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 100%;
  }

  /* Handle pre and code blocks */
  & pre, & code {
    white-space: pre-wrap;
    max-width: 100%;
    overflow-x: auto;
  }

  /* Handle links */
  & a {
    word-break: break-all;
  }

  ${(props) =>
    props.sender === "user"
      ? `
        font-weight: 400;
      `
      : `
        font-weight: 300;
      `}

  &:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: inherit;
    bottom: -6px;
    ${(props) => (props.sender === "user" ? "right: 20px;" : "left: 20px;")}
    transform: rotate(45deg);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 100%;
    padding: 14px 18px;
    font-size: 0.95rem;
  }

  ${(props) =>
    props.sender !== "user" &&
    css`
      animation: ${pulseAnimation} 2s infinite;
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    gap: 12px;
    margin-top: 20px;
  }
`;

export const LoadingMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 24px;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 0.9rem;

  .typing-indicator {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
  }

  .typing-indicator span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.v2.textSecondary};
    opacity: 0.7;
    animation: typing 1.4s infinite ease-in-out;
  }

  .typing-indicator span:nth-child(1) {
    animation-delay: 0s;
  }

  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes typing {
    0%, 100% {
      transform: translateY(0);
      opacity: 0.7;
    }
    50% {
      transform: translateY(-4px);
      opacity: 1;
    }
  }
`;

export const CustomButton = styled.button`
  background: ${(props) => props.theme.palette.buttonBackground};
  color: ${(props) => props.theme.palette.buttonText};
  border: none;
  width: 100%;
  padding: 14px 24px;
  border-radius: 12px;
  cursor: pointer;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;

  &:hover {
    background-color: ${(props) => props.theme.palette.primaryDark};
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 12px 20px;
    font-size: 14px;
  }
`;

export const MessageContent = styled.div`
  position: relative;
`;

export const FileContainer = styled.div`
  margin-top: 16px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
`;

export const FileImage = styled.img`
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
`;

export const FileAudio = styled.audio`
  margin-top: 8px;
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
`;

export const FileOther = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: ${(props) => props.theme.palette.lightBackground};
  border-radius: 12px;
`;

export const FileIcon = styled.div`
  font-size: 24px;
  margin-right: 12px;
  color: ${(props) => props.theme.palette.primary};
`;

export const FileName = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.palette.text};
`;

export const MessageGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.sender === "user" ? "flex-end" : "flex-start"};
`;

export const ToolMessage = styled.span`
  font-style: italic;
  margin-left: 8px;
  color: ${(props) => props.theme.palette.secondaryText};
`;

export const ToolProgress = styled(LinearProgress)`
  width: 100%;
  margin-top: 8px;
`;

export const OptionChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
`;

export const OptionChip = styled.button`
  padding: 6px 16px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 999px;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: #333;
  cursor: pointer;
  font-weight: normal;
  box-shadow: none;
  text-transform: none;
  min-width: auto;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  outline: none;
  position: relative;

  &:hover {
    border-color: #2563eb;
    color: #2563eb;
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

export const QuickOptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-top: 16px;
`;

export const QuickOptionButton = styled(motion.button)`
  display: flex;
  align-items: center;
  padding: 16px;
  background: ${props => props.theme.palette.v2.secondary};
  color: ${props => props.theme.palette.v2.textSecondary};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: all 0.3s ease;
  font-family: "Lato", sans-serif;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(1px);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    font-size: 20px;
    background: ${props => props.theme.palette.v2.primary};
    color: ${props => props.theme.palette.v2.textPrimary};
    border-radius: 8px;
  }

  .text {
    color: inherit;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3;
  }

  @media (max-width: 640px) {
    padding: 14px;
    
    .icon {
      width: 28px;
      height: 28px;
      font-size: 18px;
      margin-right: 10px;
    }

    .text {
      font-size: 14px;
    }
  }
`;

export const WelcomeContainer = styled(motion.div)`
  padding: 24px;
  background: ${props => props.theme.palette.v2.secondary};
  color: ${props => props.theme.palette.v2.textSecondary};
  border-radius: 24px;
  margin: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-family: "Lato", sans-serif;
`;

export const WelcomeTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: inherit;
  font-family: inherit;
  line-height: 1.5;
`;

export const WelcomeDescription = styled.p`
  color: inherit;
  margin-bottom: 16px;
  font-size: 1.125rem;
  line-height: 1.5;
  font-family: inherit;
  opacity: 0.9;

  @media (max-width: 640px) {
    font-size: 1rem;
  }
`;
