export const countries = [
  { code: 'IT', prefix: '+39', flag: '🇮🇹' },
  { code: 'US', prefix: '+1', flag: '🇺🇸' },
  { code: 'GB', prefix: '+44', flag: '🇬🇧' },
  { code: 'DE', prefix: '+49', flag: '🇩🇪' },
  { code: 'FR', prefix: '+33', flag: '🇫🇷' },
  { code: 'ES', prefix: '+34', flag: '🇪🇸' },
  { code: 'PT', prefix: '+351', flag: '🇵🇹' },
  { code: 'CH', prefix: '+41', flag: '🇨🇭' },
  { code: 'AT', prefix: '+43', flag: '🇦🇹' },
  { code: 'BE', prefix: '+32', flag: '🇧🇪' },
  { code: 'NL', prefix: '+31', flag: '🇳🇱' },
  { code: 'SE', prefix: '+46', flag: '🇸🇪' },
  { code: 'NO', prefix: '+47', flag: '🇳🇴' },
  { code: 'DK', prefix: '+45', flag: '🇩🇰' },
  { code: 'FI', prefix: '+358', flag: '🇫🇮' },
  { code: 'PL', prefix: '+48', flag: '🇵🇱' },
  { code: 'AU', prefix: '+61', flag: '🇦🇺' },
  { code: 'NZ', prefix: '+64', flag: '🇳🇿' },
  { code: 'CA', prefix: '+1', flag: '🇨🇦' },
  { code: 'JP', prefix: '+81', flag: '🇯🇵' },
  { code: 'KR', prefix: '+82', flag: '🇰🇷' },
  { code: 'CN', prefix: '+86', flag: '🇨🇳' },
  { code: 'IN', prefix: '+91', flag: '🇮🇳' },
  { code: 'BR', prefix: '+55', flag: '🇧🇷' },
  { code: 'MX', prefix: '+52', flag: '🇲🇽' },
  { code: 'RU', prefix: '+7', flag: '🇷🇺' },
  { code: 'AE', prefix: '+971', flag: '🇦🇪' },
  { code: 'SA', prefix: '+966', flag: '🇸🇦' },
  { code: 'SG', prefix: '+65', flag: '🇸🇬' },
  { code: 'IE', prefix: '+353', flag: '🇮🇪' }
]; 