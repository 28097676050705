import {
  SmartToy as AIIcon,
  Close as CloseIcon,
  Person as PersonIcon,
  AttachFile as AttachIcon,
  CropSquare as MaximizeIcon,
  MinimizeRounded as MinimizeIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  FaCompressArrowsAlt,
  FaExpandArrowsAlt,
  FaHeart,
} from "react-icons/fa";
import styled from "styled-components";
import { lightenColor } from "../utils/colors";
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";
import WishlistPopup from "./WishlistPopup";
import { trackChatEvent } from '../utils/analytics';

const HeaderContainer = styled(motion.div)`
  border-bottom: 1px solid ${(props) => props.theme.palette.borderColor};
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledAppBar = styled.div`
  color: ${(props) => props.theme.palette.v2.textPrimary};
  background: linear-gradient(90deg, 
    ${(props) => props.theme.palette.v2.primary} 0%,
    ${(props) => lightenColor(props.theme.palette.v2.primary, 10)} 100%
  );
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(1, 2)};
`;

const AnimatedAvatar = styled(motion.create(Avatar))`
  width: 40px;
  height: 40px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.isOnline ? props.theme.palette.success.main : props.theme.palette.warning.main)};
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid ${(props) => props.theme.palette.background.paper};
  animation: ${props => props.isOnline ? 'pulse 2s infinite' : 'none'};
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${props => `${props.theme.palette.success.main}66`};
    }
    70% {
      box-shadow: 0 0 0 6px ${props => `${props.theme.palette.success.main}00`};
    }
    100% {
      box-shadow: 0 0 0 0 ${props => `${props.theme.palette.success.main}00`};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.v2.textPrimary};
  transition: transform 0.2s ease-in-out;
  
  &:hover {
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ChatHeader = ({
  isMobile,
  toggleChat,
  toggleChatWidth,
  isChatWide,
  wishlistItems,
  onWishlistUpdate,
  onSendMessage,
  isLoading,
  isHumanControlled,
  humanControlMessage,
  isOnline,
}) => {
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);
  const [typingEffect, setTypingEffect] = useState("");
  const [showControlMessage, setShowControlMessage] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const [hasCompletedFirstCycle, setHasCompletedFirstCycle] = useState(false);
  const theme = useTheme();

  const messages = [
    <span style={{ fontSize: '0.85rem' }}>
      Utilizzando la chat, accetti la nostra Privacy Policy.
    </span>,
    <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.85rem', gap: '4px' }}>
      Fai click sull'icona <FaHeart size={12}/> per aggiungere l'auto ai preferiti!
    </span>,
    <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.85rem', gap: '4px' }}>
      Fai click sull'icona <AttachIcon sx={{ fontSize: '14px' }}/> per cercare un'auto simile!
    </span>,
    !isMobile && (
      <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.85rem', gap: '4px' }}>
        Fai click su <MaximizeIcon sx={{ fontSize: '14px' }}/> per espandere la chat
      </span>
    ),
    <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.85rem' }}>
      <img
        src="https://pub-5a04dbd5bce24aa3af2774b9950d8293.r2.dev/Mia_Logo_Black.png"
        alt="MIA Logo"
        width="42"
        height="15"
        style={{ marginRight: "4px" }}
      />
      può commettere errori, verifica le informazioni.
    </span>
  ].filter(Boolean);

  const handleWishlistToggle = () => {
    trackChatEvent.openWishlist();
    setIsWishlistOpen(!isWishlistOpen);
  };

  useEffect(() => {
    if (isHumanControlled) {
      setShowControlMessage(true);
      setTypingEffect(humanControlMessage || "Un operatore è ora disponibile.");
    } else {
      setShowControlMessage(true);
      setTypingEffect(messages[messageIndex]);
    }
  }, [messageIndex, isHumanControlled, humanControlMessage]);

  const handleMessageComplete = () => {
    if (!isHumanControlled) {
      if (!hasCompletedFirstCycle) {
        requestAnimationFrame(() => {
          setMessageIndex((prev) => {
            const nextIndex = (prev + 1) % messages.length;
            if (nextIndex === 0) {
              setHasCompletedFirstCycle(true);
            }
            return nextIndex;
          });
        });
      }
    }
  };

  return (
    <StyledComponentsThemWrapper>
      <HeaderContainer
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <StyledAppBar position="static" elevation={3}>
          <StyledToolbar>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AnimatedAvatar
                src={localStorage.getItem("logo")}
                alt="Chat Logo"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <StatusIndicator isOnline={isOnline} />
              </AnimatedAvatar>
              <Box>
                <Typography variant="h6" color={theme.palette.v2.textPrimary}>
                  {localStorage.getItem("name_chat")}
                </Typography>
                <Typography variant="caption">
                  {isHumanControlled ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PersonIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                      Operatore online
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AIIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                      Assistente AI
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <AnimatePresence>
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <Badge
                    badgeContent={wishlistItems.length > 0 ? wishlistItems.length : null}
                    color="secondary"
                  >
                    <StyledIconButton
                      aria-label="wishlist"
                      onClick={handleWishlistToggle}
                      disabled={isLoading}
                    >
                      <FaHeart style={{ color: theme.palette.v2.textPrimary }} />
                    </StyledIconButton>
                  </Badge>
                </motion.div>
              </AnimatePresence>
              {!isMobile && (
                <>
                  <StyledIconButton 
                    onClick={() => {
                      trackChatEvent.toggleChatSize(isChatWide ? 'small_size' : 'large_size');
                      toggleChatWidth();
                    }}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                  >
                    {isChatWide ? 
                      <MinimizeIcon style={{ 
                        color: theme.palette.v2.textPrimary,
                        fontSize: '1.2rem'
                      }} /> : 
                      <MaximizeIcon style={{ 
                        color: theme.palette.v2.textPrimary,
                        fontSize: '1.2rem'
                      }} />
                    }
                  </StyledIconButton>
                  <StyledIconButton 
                    onClick={() => {
                      trackChatEvent.toggleChat(false);
                      toggleChat();
                    }}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      }
                    }}
                  >
                    <CloseIcon sx={{ 
                      color: theme.palette.v2.textPrimary,
                      fontSize: '1.2rem'
                    }} />
                  </StyledIconButton>
                </>
              )}
              {isMobile && (
                <StyledIconButton 
                  onClick={() => {
                    trackChatEvent.toggleChat(false);
                    toggleChat();
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    }
                  }}
                >
                  <CloseIcon sx={{ 
                    color: theme.palette.v2.textPrimary,
                    fontSize: '1.2rem'
                  }} />
                </StyledIconButton>
              )}
            </Box>
          </StyledToolbar>
        </StyledAppBar>
        <Box
          sx={{
            padding: theme.spacing(0.5, 2),
            backgroundColor: isHumanControlled
              ? `${theme.palette.success.main}1A`
              : `linear-gradient(90deg, 
                  ${lightenColor(theme.palette.v2.primary, 28)} 0%,
                  ${lightenColor(theme.palette.v2.primary, 32)} 100%
                )`,
            overflow: "hidden",
            position: "relative",
            minHeight: "32px",
            maxHeight: "32px",
            width: "100%",
            boxSizing: "border-box",
            "&::before, &::after": {
              content: '""',
              position: "absolute",
              top: 0,
              bottom: 0,
              width: isChatWide ? "60px" : "20px",
              zIndex: 1
            },
            "&::before": {
              left: 0,
              background: `linear-gradient(90deg, ${theme.palette.background.paper}80 0%, transparent 100%)`
            },
            "&::after": {
              right: 0,
              background: `linear-gradient(90deg, transparent 0%, ${theme.palette.background.paper}80 100%)`
            }
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={`${messageIndex}-${isChatWide}`}
              initial={{ x: isMobile ? "120%" : isChatWide ? "300%" : "100%" }}
              animate={{ x: isMobile ? "-120%" : isChatWide ? "-300%" : "-100%" }}
              exit={{ x: isMobile ? "-140%" : isChatWide ? "-400%" : "-150%" }}
              onAnimationComplete={handleMessageComplete}
              transition={{ 
                duration: isMobile ? 6 : isChatWide ? 20 : 8,
                ease: "linear",
                delay: 0
              }}
              style={{
                position: "absolute",
                whiteSpace: "nowrap",
                width: "auto",
                paddingLeft: isMobile ? "10px" : isChatWide ? "200px" : "20px",
                paddingRight: isMobile ? "10px" : isChatWide ? "200px" : "20px"
              }}
            >
              <Typography 
                variant="body2" 
                sx={{ 
                  fontStyle: "italic",
                  whiteSpace: "nowrap",
                  fontSize: isMobile ? "0.8rem" : "0.85rem"
                }}
              >
                {messages[messageIndex]}
              </Typography>
            </motion.div>
          </AnimatePresence>
        </Box>
      </HeaderContainer>
      <WishlistPopup
        open={isWishlistOpen}
        handleClose={() => setIsWishlistOpen(false)}
        wishlistItems={wishlistItems}
        onWishlistUpdate={onWishlistUpdate}
        onSendMessage={onSendMessage}
      />
    </StyledComponentsThemWrapper>
  );
};

export default ChatHeader;
