import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { mapItems, propertyMappings } from "../utils/utils";
import { postWhislistApi } from "./api";
import { trackChatEvent } from "../utils/analytics";

const API_DOMAIN = process.env.API_DOMAIN || "https://admin.aitomotivelab.com";
const WS_DOMAIN = process.env.WEBSOCKET_URL || "wss://admin.aitomotivelab.com";

// WebSocket paths
const WS_PATH = "/ws/ai_response/";
const WEBSOCKET_URL = `${WS_DOMAIN}${WS_PATH}`;

// API paths
const TOKEN_PATH = "/user_management/oauth/token/";
const SET_COOKIES_PATH = "/ai_app/set_cookies/";

const TOKEN_URL = `${API_DOMAIN}${TOKEN_PATH}`;
const SET_COOKIES_URL = `${API_DOMAIN}${SET_COOKIES_PATH}`;

const api = axios.create();

let areCookiesSet = false;
let client_id = null;
let client_secret = null;
let grant_type = null;
let api_token = null;
let form_registration_id = null;
let accessToken = null;
let codice_concessionario = null;
let primary_color = null;
let secondary_color = null;
let background_color = null;
let text_color = null;
let logo = null;
let name_chat = null;

const FORM_TRACKING_DEBOUNCE = 500; // 500ms debounce

const loggedCarousels = new Set(); // Track logged carousels

const hashString = (str) => {
  // Get content-based components
  const contentParts = str.split('|').map(part => part.trim());
  const urlPart = contentParts[0]?.replace(/[^a-z0-9]/gi, '').slice(-8) || '';
  const titlePart = contentParts[2]?.replace(/[^a-z0-9]/gi, '').slice(0, 8) || '';
  
  // Generate hash
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  
  // Convert components to base36
  const timestamp = Date.now().toString(36);
  const hashPart = Math.abs(hash).toString(36);
  const randomPart = Math.random().toString(36).substr(2, 4);
  
  // Combine all parts with content
  return `${timestamp}_${urlPart}_${titlePart}_${hashPart}${randomPart}`.toLowerCase();
};

const useChatWebSocket = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedWishlist, setLoadedWishlist] = useState(false);
  const [colors, setColors] = useState(null);
  const [connectionError, setConnectionError] = useState(false);

  const [chatId, setChatId] = useState(() => {
    const storedChatId = localStorage.getItem(
      "aitomotivelab_personalData_chat_id"
    );
    return storedChatId &&
      storedChatId !== "undefined" &&
      storedChatId !== "null"
      ? storedChatId
      : null;
  });
  const chatIdRef = useRef(chatId);

  useEffect(() => {
    chatIdRef.current = chatId;
    if (
      chatId &&
      (!localStorage.getItem("aitomotivelab_personalData_chat_id") ||
        localStorage.getItem("aitomotivelab_personalData_chat_id") ===
          "undefined" ||
        localStorage.getItem("aitomotivelab_personalData_chat_id") === "null")
    ) {
      localStorage.setItem("aitomotivelab_personalData_chat_id", chatId);
    }
  }, [chatId]);

  const [wishlistItems, setWishlistItems] = useState([]);
  const [error, setError] = useState(null);
  const lastUserMessage = useRef("");
  const currentMessageRef = useRef(null);
  const websocketRef = useRef(null);
  const initializeWebSocketRef = useRef(null);
  const [isHumanControlled, setIsHumanControlled] = useState(false);
  const [humanControlMessage, setHumanControlMessage] = useState("");

  const [isReconnecting, setIsReconnecting] = useState(false);
  const reconnectTimeoutRef = useRef(null);
  const reconnectAttempts = useRef(0);
  const MAX_RECONNECT_ATTEMPTS = 5;
  const RECONNECT_INTERVAL = 5000; // 5 seconds

  const [reconnectCountdown, setReconnectCountdown] = useState(0);

  const hasTrackedFormShown = useRef(false);
  const formDisplayCount = useRef(0);
  const lastTrackingTimestamp = useRef(0);
  const hasTrackedRef = useRef(false);

  const [isChatVisible, setIsChatVisible] = useState(false);

  // Add new state for notification
  const [notification, setNotification] = useState(null);

  // Add new state/refs for navigation tracking
  const navigationStatsInterval = useRef(null);
  const lastPageUrl = useRef(window.location.href);

  // Initialize ref from localStorage or current time
  const lastEventTimestampRef = useRef(
    parseInt(localStorage.getItem('last_page_timestamp')) || Date.now()
  );

  // Add new state for active trigger
  const [activeTrigger, setActiveTrigger] = useState(null);

  // Initialize from localStorage and cleanup on unmount
  useEffect(() => {
    const storedCount = localStorage.getItem('form_display_count');
    if (storedCount) {
      formDisplayCount.current = parseInt(storedCount, 10);
    }
    
    return () => {
      hasTrackedFormShown.current = false;
    };
  }, []);

  const trackFormShown = useCallback(() => {
    const now = Date.now();
    // Prevent tracking if less than debounce time has passed
    if (now - lastTrackingTimestamp.current < FORM_TRACKING_DEBOUNCE) {
      return;
    }

    if (!hasTrackedFormShown.current) {
      hasTrackedFormShown.current = true;
      lastTrackingTimestamp.current = now;
      formDisplayCount.current += 1;
      localStorage.setItem('form_display_count', formDisplayCount.current.toString());
      
      trackChatEvent.formInteraction('personal_data', 'form_shown', {
        display_count: formDisplayCount.current,
        timestamp: new Date().toISOString()
      });
    }
  }, []);

  const initializeApi = async () => {
    if (areCookiesSet) return;

    try {
      const response = await api.post(SET_COOKIES_URL, {
        fullUrl: window.location.href // Pass full URL in the body
      });
      client_id = response.data.client_id;
      client_secret = response.data.client_secret;
      grant_type = response.data.grant_type;
      api_token = response.data.api_token;
      form_registration_id = response.data.form_registration_id;
      primary_color = response.data.primary_color;
      secondary_color = response.data.secondary_color;
      background_color = response.data.background_color;
      text_color = response.data.text_color;
      logo = response.data.logo;
      name_chat = response.data.name;
      codice_concessionario = response.data.codice_concessionario;

      //set colors using setColors state
      setColors({
        primary: response.data.primary_color,
        textPrimary: response.data.text_primary_color,
        secondary: response.data.secondary_color,
        textSecondary: response.data.text_secondary_color,
      });

      areCookiesSet = true;
      localStorage.setItem("api_token", api_token);
      localStorage.setItem("primary_color", primary_color);
      localStorage.setItem("secondary_color", secondary_color);
      localStorage.setItem("background_color", background_color);
      localStorage.setItem("text_color", text_color);
      localStorage.setItem("logo", logo);
      localStorage.setItem("name_chat", name_chat);
      localStorage.setItem("codice_concessionario", codice_concessionario);
      localStorage.setItem("company_name", response.data.company_name);
      localStorage.setItem("welcome_message", response.data.welcome_message);
      localStorage.setItem("message_1", response.data.message_1);
      localStorage.setItem("message_2", response.data.message_2);
      localStorage.setItem("message_3", response.data.message_3);
    } catch (error) {
      console.error("Error initializing API:", error);
      setError("Failed to initialize API. Please refresh the page.");
    }
  };

  const getAccessToken = async () => {
    if (accessToken) {
      return accessToken;
    }

    try {
      const response = await api.post(
        TOKEN_URL,
        {
          client_id: client_id,
          client_secret: client_secret,
          grant_type: grant_type,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      accessToken = response.data.access_token;
      return accessToken;
    } catch (error) {
      console.error("Error obtaining access token:", error);
      throw new Error("Failed to obtain access token");
    }
  };

  // Define attemptReconnect first
  const attemptReconnect = useCallback(() => {
    if (isReconnecting) return;

    setIsReconnecting(true);
    reconnectAttempts.current += 1;

    if (reconnectAttempts.current <= MAX_RECONNECT_ATTEMPTS) {
      const delay = Math.min(1000 * Math.pow(2, reconnectAttempts.current - 1), 10000); // Cap at 10 seconds
      console.log(`Attempting to reconnect in ${delay/1000} seconds...`);
      setReconnectCountdown(Math.floor(delay / 1000));

      reconnectTimeoutRef.current = setTimeout(() => {
        if (initializeWebSocketRef.current) {
          initializeWebSocketRef.current();
        }
      }, delay);
    } else {
      console.log("Max reconnection attempts reached");
      setError(
        "Failed to reconnect after multiple attempts. Please refresh the page."
      );
      setIsReconnecting(false);
    }
  }, [isReconnecting]);

  // Then define initializeWebSocket
  const initializeWebSocket = useCallback(async () => {
    // Prevent multiple simultaneous initialization attempts
    if (websocketRef.current?.readyState === WebSocket.CONNECTING) {
      return;
    }

    if (websocketRef.current?.readyState === WebSocket.OPEN) {
      websocketRef.current.close();
    }

    try {
      // Only initialize API if not already initialized
      if (!areCookiesSet) {
        await initializeApi();
      }
      const token = await getAccessToken();

      const ws = new WebSocket(WEBSOCKET_URL);
      websocketRef.current = ws;

      ws.onopen = () => {
        setIsConnected(true);
        setIsReconnecting(false);
        setConnectionError(false); // Reset connection error state
        reconnectAttempts.current = 0;
        setError(null);
        
        // Send authentication message only after connection is established
        setTimeout(() => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(
              JSON.stringify({
                type: "authenticate",
                token: token,
                fullUrl: window.location.href,
              })
            );
          }
        }, 100);
      };

      ws.onmessage = handleWebSocketMessage;

      ws.onclose = (event) => {
        setIsConnected(false);
        setIsAuthenticated(false);
        
        // Don't attempt to reconnect if it was a normal closure
        if (event.code !== 1000) {
          setConnectionError(true);
          setError("WebSocket disconnected. Attempting to reconnect...");
          attemptReconnect();
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        setIsConnected(false);
        setConnectionError(true);
        setError("WebSocket connection error. Attempting to reconnect...");
        
        // Trigger reconnection on error
        if (ws.readyState !== WebSocket.OPEN) {
          attemptReconnect();
        }
      };
    } catch (error) {
      console.error("Error initializing WebSocket:", error);
      setError(
        error.message || "Failed to initialize chat. Attempting to reconnect..."
      );
      setConnectionError(true);
      attemptReconnect();
    }
  }, [attemptReconnect]);

  // Assign initializeWebSocket to the ref
  initializeWebSocketRef.current = initializeWebSocket;

  useEffect(() => {
    initializeWebSocket();
    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [initializeWebSocket]);

  useEffect(() => {
    let countdownInterval;
    if (reconnectCountdown > 0) {
      countdownInterval = setInterval(() => {
        setReconnectCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    };
  }, [reconnectCountdown]);

  const handleManualReconnect = () => {
    reconnectAttempts.current = 0;
    setError(null);
    if (initializeWebSocketRef.current) {
      initializeWebSocketRef.current();
    }
  };

  const handleWebSocketMessage = (event) => {
    const data = JSON.parse(event.data);
    console.log("Received data:", data);

    if (data.type === "authentication") {
      setIsAuthenticated(data.success);
      if (!data.success) {
        setError("Authentication failed. Please refresh the page.");
      } else {
        const storedChatId = chatIdRef.current || localStorage.getItem("aitomotivelab_personalData_chat_id");
        
        if (storedChatId && storedChatId !== "undefined" && storedChatId !== "null") {
          websocketRef.current?.send(
            JSON.stringify({
              type: "join_chat",
              chat_id: storedChatId,
            })
          );
        } else if (websocketRef.current?.readyState === WebSocket.OPEN) {
          if (!chatIdRef.current) {
            websocketRef.current.send(
              JSON.stringify({
                type: "create_chat",
              })
            );
          }
        }
      }
    } else if (data.type === "chat_created" || data.type === "chat_joined") {
      if (data.chat_id) {
        setChatId(data.chat_id);
        chatIdRef.current = data.chat_id;
        localStorage.setItem("aitomotivelab_personalData_chat_id", data.chat_id);
        fetchChatHistory();
      }
    } else if (data.type === "error" && data.message === "Chat not found") {
      setChatId(null);
      chatIdRef.current = null;
      if (event.target && event.target.readyState === WebSocket.OPEN) {
        event.target.send(
          JSON.stringify({
            type: "create_chat",
          })
        );
      } else {
        setError("WebSocket is not connected. Please try again.");
      }
    } else if (
      data.type === "stream_message" ||
      data.type === "message_complete" ||
      data.type === "admin_message" ||
      data.type === "predicted_questions"
    ) {
      handleIncomingMessage(data);
    } else if (data.type === "human_control_update") {
      setIsHumanControlled(data.human_control);
      setHumanControlMessage(data.message || "An operator is now available.");
    } else if (data.type === "chat_history") {
      let parsedHistory;
      try {
        parsedHistory = JSON.parse(data.chat_history);
      } catch (parseError) {
        setError("Invalid chat history format");
        return;
      }
      const formattedHistory = parsedHistory.flatMap((item) =>
        formatChatHistoryItem(item.fields, item.pk)
      );
      setMessages(formattedHistory);
    } else if (data.type === "open_chat") {
      if (data.duration) {
        setTimeout(() => {
          setIsChatVisible(true);
          if (data.message) {
            handleIncomingMessage({
              type: 'message_complete',
              message: data.message,
              sender: 'server'
            });
          }
        }, data.duration);
      } else {
        //setIsChatVisible(true);
        if (data.message) {
          handleIncomingMessage({
            type: 'message_complete',
            message: data.message,
            sender: 'server'
          });
        }
      }
      return;
    } else if (data.type === "show_notification") {
      // Clear any existing notification first
      setNotification(null);
      
      // Set new notification with a small delay to ensure animation triggers
      setTimeout(() => {
        setNotification({
          message: data.message,
          duration: data.duration || 10000
        });
      }, 100);
      
      return;
    } else if (data.type === "navigation_trigger") {
      // Store trigger ID from the top level of the message
      setActiveTrigger(data.trigger_id);
      
      const trigger = data.trigger_data;
      switch (trigger.type.toUpperCase()) {
        case 'OPEN_CHAT':
          setIsChatVisible(true);
          setIsLoading(false);
          if (trigger.message) {
            handleIncomingMessage({
              type: 'message_complete',
              message: trigger.message,
              sender: 'server'
            });
          }
          break;

        case 'OPEN_CHAT_WITH_CUSTOM_MESSAGE':
          setIsChatVisible(true);
          setIsLoading(true);
          ;  // Add loading state
          break;
        case 'SHOW_NOTIFICATION_WITH_CUSTOM_MESSAGE':
          // Clear any existing notification first
          setNotification(null);
          setIsLoading(false);
          // Set new notification with a small delay to ensure animation triggers
          setTimeout(() => {
            setNotification({
              visible_message: trigger.custom_message, 
              message_to_send: trigger.message,
              duration: trigger.duration || 10000,
              type: trigger.type.toLowerCase()
            });
          }, 100);
          break;
      }
    }
  };

  const fetchChatHistory = () => {
    if (
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      websocketRef.current.send(
        JSON.stringify({
          type: "fetch_history",
        })
      );
    } else {
      setError("Failed to fetch chat history. Please try again.");
    }
  };

  const formatChatHistoryItem = (message, id) => {
    const formattedMessages = [];
    if (
      message.sender_message !== null 
    ) {
      formattedMessages.push({
        sender: "user",
        text: message.sender_message,
        id,
        file_url: message.sender_file_url || null,
      });
    } else if (message.sender_file_url) {
      formattedMessages.push({
        sender: "user",
        text: "File inviato",
        id,
        file_url: message.sender_file_url,
      });
    }

    // First push the bot message if it exists
    if (message.bot_message || (message.tool && message.tool.includes("car_stock"))) {
      let formattedMessage = {
        sender: "server",
        tool: message.tool || null,
        text: message.bot_message || "",
        id: `${id}-message`,
        file_url: message.bot_file_url || null,
        whishlist: message.whishlist,
      };

      if (
        message.sources &&
        message.tool &&
        message.tool.includes("car_stock")
      ) {
        try {
          if (
            Array.isArray(message.sources) &&
            propertyMappings[message.tool]
          ) {
            formattedMessage.carouselItems = mapItems(
              message.sources,
              propertyMappings[message.tool]
            );
          }
        } catch (error) {
          console.warn("Error processing carousel items:", error);
        }
      }

      formattedMessages.push(formattedMessage);
    }

    // Then push predicted questions as a separate message if they exist
    if (message.predicted_questions) {
      // Parse the JSON string at formatting time
      let parsedQuestions;
      try {
        parsedQuestions = JSON.parse(message.predicted_questions);
      } catch (e) {
        console.error('Failed to parse predicted questions:', e);
        parsedQuestions = null;
      }
    
      if (parsedQuestions) {
        formattedMessages.push({
          sender: "server",
          type: "predicted_questions",
          questions: Object.values(parsedQuestions), // Convert object values to array
          id: `${id}-questions`,
        });
      }
    }

    return formattedMessages;
  };

  const getAllVehicleInfosFromMessages = () => {
    const allVehicleInfo = messages?.reduce((r, v) => {
      if (v.carouselItems) {
        return [...r, ...v.carouselItems];
      }
      return r;
    }, []);
    return allVehicleInfo;
  };

  const updateWishlistFromMessages = () => {
    const vehiclesInfo = getAllVehicleInfosFromMessages();
    const messageWithWishlist = messages?.find(({ whishlist }) =>
      Boolean(whishlist)
    );
    const wishlistWithInfo = messageWithWishlist?.whishlist
      .split(", ")
      ?.map((id) => vehiclesInfo.find(({ vehicleid }) => vehicleid === id))
      .filter(item => item && item.available !== false); // Filter out unavailable vehicles
    setWishlistItems(wishlistWithInfo || []);
  };

  useEffect(() => {
    if (messages.length > 0 && !loadedWishlist) {
      updateWishlistFromMessages();
      setLoadedWishlist(true);
    }
  }, [messages, loadedWishlist, updateWishlistFromMessages, setLoadedWishlist]);

  const handleWishlistUpdate = async (vehicleId) => {
    try {
      const messageId = messages.find(({ carouselItems }) =>
        carouselItems?.find(({ vehicleid }) => vehicleId === vehicleid)
      ).id;
      const numericMessageId = parseInt(messageId, 10);
      if (isNaN(numericMessageId)) {
        throw new Error("Invalid message ID");
      }
      const response = await postWhislistApi(numericMessageId, vehicleId);
      if (response) {
        if (wishlistItems?.some(({ vehicleid: vId }) => vehicleId === vId)) {
          setWishlistItems((wishlistItems) =>
            wishlistItems?.filter(({ vehicleid: vId }) => vehicleId !== vId)
          );
        } else {
          const vehiclesInfo = getAllVehicleInfosFromMessages();
          const vehicleInfo = vehiclesInfo.find(
            ({ vehicleid }) => vehicleid === vehicleId
          );
          setWishlistItems((wishlistItems) => [...wishlistItems, vehicleInfo]);
        }
      } else {
        throw new Error("Failed to update wishlist");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      setError("Failed to update wishlist. Please try again.");
    }
  };

  const handleIncomingMessage = useCallback((data) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      let currentMessage;

      // Handle admin messages separately and always add them as new messages
      if (data.type === "admin_message") {
        currentMessage = {
          sender: "server",
          text: data.message,
          loading: false,
          status: undefined,
          tool: data.tool || [],
          id: data.id,
        };
        
        // Update this section to properly map carousel items for admin messages
        if (data.sources && Array.isArray(data.sources)) {
          try {
            // Use the car_stock_search mapping for admin messages with that tool
            const mappingKey = data.tool === 'car_stock_search' ? 'car_stock_search' : data.tool;
            if (propertyMappings[mappingKey]) {
              currentMessage.carouselItems = mapItems(
                data.sources,
                propertyMappings[mappingKey]
              );
            } else {
              // Fallback to raw sources if no mapping exists
              currentMessage.carouselItems = data.sources;
            }

            // Track carousel logic
            const carouselId = data.sources.map(item => item.vehicleid).join(',');
            if (!loggedCarousels.has(carouselId)) {
              const vehicleInfo = data.sources.map(item => ({
                id: item.vehicleid,
                title: item.description,
                brand: item.brand,
                usagetype: item.usagetype,
                km: item.km
              }));

              trackChatEvent.carouselShown(data.sources.length, vehicleInfo);
              loggedCarousels.add(carouselId);
            }
          } catch (mappingError) {
            console.warn("Error mapping admin message items:", mappingError);
          }
        }
        currentMessage.whishlist = data.whishlist || "";
        
        // Always push admin messages as new messages
        newMessages.push(currentMessage);
        return newMessages;
      }

      // Handle predicted questions as a separate case
      if (data.type === "predicted_questions") {
        let questions = [];
        
        // Handle different question formats
        if (data.questions) {
          if (typeof data.questions === 'object' && !Array.isArray(data.questions)) {
            // If it's an object, extract the values
            questions = Object.values(data.questions).map(q => q?.toString() || '');
          } else if (Array.isArray(data.questions)) {
            // If it's already an array, just map to strings
            questions = data.questions.map(q => q?.toString() || '');
          }
        }

        currentMessage = {
          sender: "server",
          type: "predicted_questions",
          questions: questions,
          id: data.id || `pred-${Date.now()}`,
          loading: false
        };
        newMessages.push(currentMessage);
        return newMessages;
      }
  
      if (data.type === "stream_message") {
        if (data.status === "tool") {
          const toolName = data.message;
          const lastMessage = newMessages[newMessages.length - 1] || {};
          const updatedToolList = Array.isArray(lastMessage.tool)
            ? [...new Set([...lastMessage.tool, toolName])]
            : [toolName];
          currentMessage = {
            ...lastMessage,
            tool: updatedToolList,
            loading: true,
            status: data.status,
          };
          if (newMessages.length > 0) {
            newMessages[newMessages.length - 1] = currentMessage;
          } else {
            newMessages.push(currentMessage);
          }
        } else {
          if (currentMessageRef.current) {
            currentMessage = {
              ...currentMessageRef.current,
              text: currentMessageRef.current.text + data.message,
              loading: true,
            };
          } else {
            currentMessage = {
              sender: "server",
              text: data.message,
              tool: [],
              id: data.id,
              loading: true,
            };
            newMessages.push(currentMessage);
          }
          currentMessageRef.current = currentMessage;
        }
      } else if (
        data.type === "message_complete" ||
        data.type === "admin_message"
      ) {
        setIsLoading(false);
        currentMessage = {
          sender: "server",
          text: data.message,
          loading: false,
          status: undefined,
          tool: data.tool || [],
          id: data.id,
        };
  
        if (data.sources && Array.isArray(data.sources)) {
          if (data.type === "admin_message" && data.tool === "car_stock") {
            try {
              currentMessage.carouselItems = mapItems(
                data.sources,
                propertyMappings["car_stock_search"]
              );

              // Track carousel logic
              const carouselId = data.sources.map(item => item.vehicleid).join(',');
              if (!loggedCarousels.has(carouselId)) {
                const vehicleInfo = data.sources.map(item => ({
                  id: item.vehicleid,
                  title: item.description,
                  brand: item.brand,
                  usagetype: item.usagetype,
                  km: item.km
                }));

                trackChatEvent.carouselShown(data.sources.length, vehicleInfo);
                loggedCarousels.add(carouselId);
              }
            } catch (mappingError) {
              console.warn("Error mapping admin message items:", mappingError);
            }
          } else if (data.tool && data.tool.includes("car_stock_search")) {
            // Original search logic
            try {
              currentMessage.carouselItems = mapItems(
                data.sources,
                propertyMappings[data.tool]
              );

              // Track carousel logic
              const carouselId = data.sources.map(item => item.vehicleid).join(',');
              if (!loggedCarousels.has(carouselId)) {
                const vehicleInfo = data.sources.map(item => ({
                  id: item.vehicleid,
                  title: item.description,
                  brand: item.brand,
                  usagetype: item.usagetype,
                  km: item.km
                }));

                trackChatEvent.carouselShown(data.sources.length, vehicleInfo);
                loggedCarousels.add(carouselId);
              }
            } catch (mappingError) {
              console.warn("Error mapping search items:", mappingError);
            }
          } else {
            currentMessage.carouselItems = data.sources;
          }
        }
        currentMessage.whishlist = data.whishlist || "";
  
        // Check if the last message is loading - if so, update it
        // Otherwise, add as a new message
        const lastMessage = newMessages[newMessages.length - 1] || {};
        if (lastMessage.loading) {
          newMessages[newMessages.length - 1] = currentMessage;
        } else {
          newMessages.push(currentMessage);
        }
        currentMessageRef.current = null;
      }
      return newMessages;
    });
  }, [trackFormShown]);

  const handleSend = async (message, file, audio, personaldata) => {
    setIsLoading(true);
    hasTrackedRef.current = false;
    if (!isAuthenticated) {
      setError("Not authenticated. Please try again.");
      return;
    }

    setError(null);
    let localUrl = null;
    let userMessage = {
      sender: "user",
      text: message || "Ti ho inviato un messaggio",
    };

    if (file) {
      localUrl = URL.createObjectURL(file);
      userMessage.file_url = localUrl;
    } else if (audio) {
      localUrl = URL.createObjectURL(audio);
      userMessage.file_url = localUrl;
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      userMessage,
      { sender: "server", text: "", loading: true },
    ]);
    lastUserMessage.current = message;
    currentMessageRef.current = { sender: "server", text: "", loading: true };

    const data = {
      type: file ? "file_message" : audio ? "audio_message" : "chat_message",
      input: message,
      file: file ? await fileToBase64(file) : null,
      file_name: file ? file.name : null,
      file_type: file ? file.type : null,
      audio: audio ? await fileToBase64(audio) : null,
      personaldata: personaldata,
      sender_url: window.location.href,
      trigger_id: activeTrigger,
      ...getPersonalDataFromLocalStorage(),
    };

    if (
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      websocketRef.current.send(JSON.stringify(data));
    } else {
      setError("WebSocket is not connected. Attempting to reconnect...");
      setIsLoading(false);
      attemptReconnect();
    }
  };

  const handleResendLastMessage = () => {
    if (lastUserMessage.current) {
      handleSend(lastUserMessage.current);
    }
  };

  const getPersonalDataFromLocalStorage = () => ({
    first_name:
      localStorage.getItem("aitomotivelab_personalData_first_name") || "",
    last_name:
      localStorage.getItem("aitomotivelab_personalData_last_name") || "",
    email: localStorage.getItem("aitomotivelab_personalData_email") || "",
    gdpr_consent:
      localStorage.getItem("aitomotivelab_personalData_gdpr") === "gdpr",
    marketing_consent:
      localStorage.getItem("aitomotivelab_personalData_marketing") ===
      "marketing",
    phone: localStorage.getItem("aitomotivelab_personalData_phone") || "",
    api_token: localStorage.getItem("api_token") || "",
  });

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Reset tracking for new conversations
  const resetFormTracking = useCallback(() => {
    hasTrackedFormShown.current = false;
    hasTrackedRef.current = false;
    formDisplayCount.current = 0;
    lastTrackingTimestamp.current = 0;
    localStorage.removeItem('form_display_count');
  }, []);

  // Update the sendNavigationStats function
  const sendNavigationStats = useCallback(() => {
    if (websocketRef.current?.readyState === WebSocket.OPEN) {
      const currentUrl = window.location.href;
      const storedTimestamp = parseInt(localStorage.getItem('last_page_timestamp')) || lastEventTimestampRef.current;
      const timeOnPage = Math.floor((Date.now() - storedTimestamp) / 1000);

      // Calculate scroll percentage
      const scrollHeight = Math.max(
        document.documentElement.scrollHeight,
        document.body.scrollHeight
      );
      const clientHeight = document.documentElement.clientHeight;
      const scrollTop = window.scrollY;
      const scrollPercentage = Math.round(
        (scrollTop / (scrollHeight - clientHeight)) * 100
      );

      /**
       * Analyzes text content significance based on patterns and structure
       */
      const isSignificantText = (text) => {
        if (!text || typeof text !== 'string') return false;
        const cleaned = text.trim();
        
        // Check for meaningful length and word count
        const words = cleaned.split(/\s+/);
        return cleaned.length >= 3 && 
               cleaned.length <= 200 && 
               words.length >= 2;
      };

      /**
       * Determines element importance based on properties and context
       */
      const isSignificantElement = (element) => {
        // Skip invisible elements
        if (element.offsetParent === null) return false;
        
        const style = window.getComputedStyle(element);
        if (style.display === 'none' || 
            style.visibility === 'hidden' || 
            style.opacity === '0') return false;

        // Skip all elements within chat-root
        const chatRoot = document.getElementById('chat-root');
        if (chatRoot && (element === chatRoot || chatRoot.contains(element))) {
          return false;
        }

        // Rest of the significance checks remain the same
        if (element.onclick || 
            element.getAttribute('role') || 
            element.tagName === 'BUTTON' || 
            element.tagName === 'A' || 
            element.tagName === 'INPUT' || 
            element.tagName === 'SELECT') return true;

        const hasTrackingAttr = Array.from(element.attributes)
          .some(attr => attr.name.startsWith('data-'));
        if (hasTrackingAttr) return true;

        return isSignificantText(element.textContent);
      };

      /**
       * Calculates element visibility score (0-1)
       */
      const getVisibilityScore = (rect, viewportHeight, viewportWidth) => {
        if (rect.width === 0 || rect.height === 0) return 0;

        const verticalVisible = Math.min(rect.bottom, viewportHeight) - 
                              Math.max(rect.top, 0);
        const horizontalVisible = Math.min(rect.right, viewportWidth) - 
                                Math.max(rect.left, 0);
        
        const visibleArea = Math.max(0, verticalVisible) * 
                           Math.max(0, horizontalVisible);
        const totalArea = rect.width * rect.height;
        
        return visibleArea / totalArea;
      };

      /**
       * Gets visible elements with their contextual data
       */
      const getVisibleElements = () => {
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;
        const scrollTop = window.scrollY;
        const visibleContent = [];
        
        // Calculate focus area (center 60% of viewport)
        const focusAreaTop = scrollTop + (viewportHeight * 0.2);
        const focusAreaBottom = scrollTop + (viewportHeight * 0.8);
        
        const walker = document.createTreeWalker(
          document.body,
          NodeFilter.SHOW_ELEMENT,
          {
            acceptNode: (node) => {
              // Skip chat elements
              const chatRoot = document.getElementById('chat-root');
              if (chatRoot && (node === chatRoot || chatRoot.contains(node))) {
                return NodeFilter.FILTER_SKIP;
              }

              // Only process elements that might contain meaningful content
              if (!isSignificantElement(node)) return NodeFilter.FILTER_SKIP;
              
              const rect = node.getBoundingClientRect();
              const elementTop = rect.top + scrollTop;
              const elementBottom = elementTop + rect.height;
              
              // Check viewport visibility
              const isInViewport = (
                elementBottom >= scrollTop &&
                elementTop <= scrollTop + viewportHeight &&
                rect.right >= 0 &&
                rect.left <= viewportWidth &&
                rect.width > 0 &&
                rect.height > 0
              );

              // Check if in focus area
              const isInFocusArea = (
                elementTop <= focusAreaBottom &&
                elementBottom >= focusAreaTop
              );

              if (isInViewport && isInFocusArea) {
                return NodeFilter.FILTER_ACCEPT;
              }
              return NodeFilter.FILTER_SKIP;
            }
          }
        );

        while (walker.nextNode()) {
          const element = walker.currentNode;
          const rect = element.getBoundingClientRect();
          
          const elementCenterY = rect.top + (rect.height / 2);
          const viewportCenterY = viewportHeight / 2;
          const distanceFromCenter = Math.abs(elementCenterY - viewportCenterY);
          const centerScore = 1 - (distanceFromCenter / (viewportHeight / 2));

          const text = element.textContent?.trim();
          if (isSignificantText(text)) {
            const contentType = element.tagName === 'A' || element.onclick ? 'interactive' : 'content';
            
            // Generate a stable hash based on content and attributes
            const hashContent = [
              text,
              element.tagName,
              element.id,
              element.className,
              element.getAttribute('href'),
              element.getAttribute('aria-label'),
              element.getAttribute('title'),
              element.getAttribute('alt'),
              element.getAttribute('data-purpose')
            ].filter(Boolean).join('|');
            
            visibleContent.push({
              text: text.substring(0, 100),
              type: contentType,
              relevance: centerScore,
              stats_id: hashString(hashContent),
              purpose: element.getAttribute('aria-label') || 
                      element.getAttribute('title') || 
                      element.getAttribute('alt') || 
                      element.getAttribute('data-purpose')
            });
          }
        }

        // Sort by relevance and clean up the output
        return visibleContent
          .sort((a, b) => b.relevance - a.relevance)
          .map(item => {
            const { relevance, ...cleanItem } = item;
            return Object.fromEntries(
              Object.entries(cleanItem).filter(([_, v]) => v != null)
            );
          });
      };

      // Generate navigation ID
      const navHashContent = [
        currentUrl,
        lastPageUrl.current,
        document.title,
        new Date().toISOString()
      ].join('|');

      const pageData = {
        type: 'navigation_stats',
        stats_id: hashString(navHashContent),
        page: {
          current_url: currentUrl,
          previous_url: lastPageUrl.current,
          title: document.title,
          time_on_page: timeOnPage,
          timestamp: new Date().toISOString(),
          scroll_percentage: scrollPercentage
        },
        viewport: {
          device_type: /Mobile|Android|iPhone/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
          width: window.innerWidth,
          height: window.innerHeight
        },
        visible_content: getVisibleElements().map(item => ({
          text: item.text.substring(0, 100),
          type: item.type,
          purpose: item.purpose
        }))
      };

      if (pageData.visible_content.length > 0) {
        websocketRef.current.send(JSON.stringify(pageData));
      }

      lastPageUrl.current = currentUrl;
      // Only update timestamp when actually changing pages
      if (currentUrl !== lastPageUrl.current) {
        const newTimestamp = Date.now();
        lastEventTimestampRef.current = newTimestamp;
        localStorage.setItem('last_page_timestamp', newTimestamp.toString());
      }
    }
  }, []);

  // Update the navigation tracking effect
  useEffect(() => {
    if (isAuthenticated && isConnected) {
      // Initialize timestamp on first connection if not already set
      if (!localStorage.getItem('last_page_timestamp')) {
        const initialTimestamp = Date.now();
        lastEventTimestampRef.current = initialTimestamp;
        localStorage.setItem('last_page_timestamp', initialTimestamp.toString());
      }

      // Send initial stats
      const sendInitialStats = () => {
        // Small delay to ensure DOM is fully loaded
        setTimeout(sendNavigationStats, 100);
      };

      // Send stats on page load
      sendInitialStats();

      // Set up interval for subsequent stats
      const interval = setInterval(sendNavigationStats, 30000);

      // Clean up
      return () => {
        clearInterval(interval);
      };
    }
  }, [isAuthenticated, isConnected, sendNavigationStats]);

  // Add effect to clear trigger only on page navigation
  useEffect(() => {
    const handlePageChange = () => {
      setActiveTrigger(null);
    };

    // Listen for navigation events
    window.addEventListener('popstate', handlePageChange);
    window.addEventListener('pushstate', handlePageChange);
    window.addEventListener('replacestate', handlePageChange);

    return () => {
      window.removeEventListener('popstate', handlePageChange);
      window.removeEventListener('pushstate', handlePageChange);
      window.removeEventListener('replacestate', handlePageChange);
    };
  }, []);

  return {
    isAuthenticated,
    isConnected,
    messages,
    isLoading,
    setIsLoading,
    wishlistItems,
    error,
    isReconnecting,
    reconnectCountdown,
    reconnectAttempts: reconnectAttempts.current,
    MAX_RECONNECT_ATTEMPTS,
    handleManualReconnect,
    handleSend,
    handleResendLastMessage,
    handleWishlistUpdate,
    isHumanControlled,
    humanControlMessage,
    colors,
    accessToken,
    resetFormTracking,
    isChatVisible,
    setIsChatVisible,
    toggleChat: () => setIsChatVisible(prev => !prev),
    notification,
    setNotification,
    activeTrigger,
  };
};

export default useChatWebSocket;
